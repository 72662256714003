import {
  getCompanyIdFromCookie,
  setCompanyCookie,
  getImpersonatedUserId,
  getJsLanguageCookie,
  removeImpersonationCookie
} from '../utils/cookie'
import { findById } from '../utils/list'
import useApi from './api'

export function useUserApi() {
  const api = useApi()
  return UserApi(api)
}

export function UserApi(api) {
  return {
    fetchUser,
    getCurrentCompany,
    getLanguage
  }

  async function fetchUser(userId) {
    const user = await fetchUserAggregate(userId)
    const impersonatedUserId = getImpersonatedUserId()
    if (!impersonatedUserId || user.userId === impersonatedUserId) return user
    const impersonation = await api.fetchUserImpersonation(impersonatedUserId)
    if (!impersonation) {
      console.error(`no impersonation of ${impersonatedUserId} for ${userId} found!`)
      return user
    }
    return impersonateUser(impersonatedUserId, user)
  }

  async function fetchUserAggregate(userId) {
    if (!userId) return null

    const [u, prefs] = await Promise.all([
      api.fetchUserDetails(userId),
      api.fetchUserPreferences(userId)
    ])

    const user = { ...prefs, ...u, userId }
    const companyId = getCompanyIdFromCookie()
    const currentCompany = await getCurrentCompany(user, companyId)
    const language = getLanguage(user, currentCompany)

    return {
      ...user,
      currentCompany,
      language,
      impersonator: null
    }
  }

  function getLanguage(user, currentCompany) {
    if (user && user.impersonator) {
      const jsLan = getJsLanguageCookie()
      if (jsLan) {
        return jsLan
      }
      user = invalidateImpersonation(user)
    }
    return currentCompany?.preferredLanguage || 'en'
  }

  function invalidateImpersonation(user) {
    removeImpersonationCookie()
    return {
      ...user,
      impersonator: null
    }
  }

  async function impersonateUser(userId, impersonator) {
    const user = await fetchUserAggregate(userId)
    const newUser = { ...user, impersonator }
    const language = getLanguage(newUser, newUser.currentCompany)
    return { ...newUser, language }
  }

  async function getCurrentCompany(user, companyId) {
    if (user.companies?.length === 1) return getSingleCompany(user)
    const company = findById(companyId, user.companies)
    return hydrateCompany(user.userId, company)
  }

  async function getSingleCompany(user) {
    const [company] = user.companies
    setCompanyCookie(company.id)
    return hydrateCompany(user.userId, company)
  }

  async function hydrateCompany(userId, company) {
    await Promise.all([
      ensureContactId(userId, company),
      ensureFeatures(company),
      ensureCountryCode(company)
    ])
    return company
  }

  async function ensureContactId(userId, company) {
    if (company) {
      company.contactId ??= await api.fetchCompanyContactId(userId, company.id)
    }
    return company
  }

  async function ensureFeatures(company) {
    if (company) {
      company.features ??= await api.fetchCompanyFeatures(company.id)
    }
    return company
  }

  async function ensureCountryCode(company) {
    if (company) {
      company.countryCode ??= await api.fetchCompanyCountryCode(company.id)
    }
    return company
  }
}
