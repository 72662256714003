import React, { useCallback, useEffect, useState } from 'react'
import { initAuth } from '@platform/platform-auth'
import { BreakpointContextProvider } from '../state/useBreakpoint'
import { ConfigContextProvider } from '../state/useConfig'
import { UserContextProvider } from '../state/useUser'
import { CompanyContextProvider } from './CompanyContextProvider'
import ReactIntlProvider from './ReactIntlProvider'
import { Loading } from '../Components/Loading'
import { RuntimeContextProvider } from '../state/useRuntime'
import { ApplicationRoutes } from './ApplicationRoutes'
import { setLoggedInCookieIsMemberApp } from '../utils/compileCookie'
import { RemoteTranslationProvider } from './RemoteTranslationProvider'

export const _PlatformApp =
  (config) =>
  ({
    localeOverride,
    routes,
    translations,
    platformDomains,
    onIntlError,
    enableSinglePageMode = false,
    useMemberAuth,
    base = ''
  }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading, setLoading] = useState(true)

    const addBasePath = useCallback(
      (path) => {
        return `/${base}${path}`.replace(/\/+/, '/')
      },
      [base]
    )

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const isMemberApp = window.location.pathname.match(/\/member\/*/)

      /*
            Because member is a collection of applications served under various route structures,
            and we stored the bearer token in the applications memory, we cannot add an oauth 
            callback route to the app in a consistent way, nor can we add a generic member oauth
            callback route. Instead, we can rely on the bearer token member has already set in the
            cookie `authentication_token`. To have the auth library treat that cookie as the source
            of truth, we can simply not call `initAuth` when on a member page.
        */
      if (useMemberAuth ?? isMemberApp) {
        setLoggedInCookieIsMemberApp(true)
        setLoading(false)
      } else {
        const callbackPath = addBasePath('/oauth-callback')
        const redirectUri = `${window.location.origin}${callbackPath}`

        initAuth({
          clientId: window.location.host.match(/member102.lab.cj.com/)
            ? 'ZUxBmByWlGV0SLxWPkRKzInn41OlCnSp'
            : 'PNSvFDQzkiyy1A668z5ZsxXGEgRGN88h',
          redirectUri
        }).then(() => setLoading(false))
      }
    }, [])

    return loading ? (
      <Loading />
    ) : (
      <ConfigContextProvider
        config={config}
        domains={platformDomains}
        enableSinglePageMode={enableSinglePageMode}
      >
        <UserContextProvider>
          <RemoteTranslationProvider>
            <ReactIntlProvider
              localeOverride={localeOverride}
              translations={translations}
              onIntlError={onIntlError}
            >
              <RuntimeContextProvider>
                <BreakpointContextProvider>
                  <CompanyContextProvider>
                    <ApplicationRoutes routes={routes} addBasePath={addBasePath} />
                  </CompanyContextProvider>
                </BreakpointContextProvider>
              </RuntimeContextProvider>
            </ReactIntlProvider>
          </RemoteTranslationProvider>
        </UserContextProvider>
      </ConfigContextProvider>
    )
  }

export const PlatformApp = _PlatformApp({ staticAssetsPath: 'https://platform.cj.com/nav/static' })
