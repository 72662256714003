import './base.css'

// Library Exports
export { PlatformApp } from './PlatformApp/PlatformApp'

export { FormModal, FormModalWithOptions } from './Components/FormModal'

export { default as shouldDisplay2022Nav } from './api/shouldDisplay2022Nav'

export { redirectToSupportCenter } from './api/redirectToSupportCenter'

export { useUser_ as useUser } from './state/useUser'

export { RuntimeContextProvider } from './state/useRuntime'

export { useLinkBlocker } from './state/useLinkBlocker'

export { useBaseNavigate } from './state/useBaseNavigate'

export {
  default as useBreakpoint,
  BreakpointContext,
  BreakpointContextProvider
} from './state/useBreakpoint'

// re-exports
export {
  IntlProvider,
  IntlContext,
  FormattedMessage,
  defineMessage,
  useIntl,
  createIntl
} from 'react-intl'

export {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  useBlocker,
  useBeforeUnload
} from 'react-router-dom'

export * as ReactRouterDom from 'react-router-dom'
