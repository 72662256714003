import Cookies from 'js-cookie'

const baseDomain = () =>
  window.location.hostname.endsWith('cj.com') ? 'cj.com' : window.location.hostname

export const setLoggedInCookieIsMemberApp = (value) => {
  Cookies.set(loggedInKey, value, { domain: baseDomain() })
}

const loggedInKey = 'nav_active_session'
