import React, { createContext, useEffect, useState, useContext } from 'react'
import { loadRuntimeScript } from '../api/api'
import { FormModalTest } from '../Components/FormModal'
import { Loading } from '../Components/Loading'

window.NavReact = React

const stubContext = {
  CompanyChooserSplash: (props) => <div data-testid="select-companies" {...props} />,
  Loading: (props) => <div data-testid="loading" {...props} />,
  PlatformNav: (props) => <div data-testid="platform-nav" {...props} />,
  FormModal: FormModalTest,
  FormModalWithOptions: FormModalTest,
  useBaseNavigate: () => () => {},
  useLinkBlocker: () => ({
    isBlocked: false,
    setIsBlocked: () => {},
    onBlockedClick: () => {},
    setOnBlockedClick: () => {}
  })
}

const isJest = () => {
  return typeof process === 'undefined'
    ? false
    : process.env.NODE_ENV === 'test' || process.env.JEST_WORKER_ID !== undefined
}

export const RuntimeContext = createContext(stubContext)

export const RuntimeContextProvider = ({ children }) => {
  const [runtime, setRuntime] = useState(null)

  useEffect(() => {
    if (isJest()) {
      setRuntime(stubContext)
    } else {
      loadRuntimeScript().then(setRuntime)
    }
  }, [])

  return (
    <RuntimeContext.Provider value={runtime}>
      {runtime ? children : <Loading />}
    </RuntimeContext.Provider>
  )
}

export const useRuntime = () => {
  const runtime = useContext(RuntimeContext)
  return runtime
}
