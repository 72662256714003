import React, { createContext, useState, useContext, useEffect } from 'react'
import * as R from 'ramda'

const emptyTranslations = { cs: {}, de: {}, es: {}, fr: {}, ja: {}, pl: {}, pt: {}, zh: {} }

const RemoteTranslationContext = createContext({
  addRemoteTranslationFile: () => {},
  remoteTranslations: emptyTranslations
})

export const RemoteTranslationProvider = ({
  children,
  defaultRemoteTranslations = emptyTranslations
}) => {
  const [remoteTranslations, setRemoteTranslations] = useState(defaultRemoteTranslations)

  const addRemoteTranslation = (deferredPromise, path) => {
    deferredPromise()
      .then((res) => {
        if (!!res) {
          setRemoteTranslations((prev) => {
            const newRemoteTranlsations = R.mergeDeepRight(prev, res)
            return newRemoteTranlsations
          })
        }
      })
      .catch(() => {
        console.error(`Failed to add remote translations for path "${path}"`)
      })
  }

  return (
    <RemoteTranslationContext.Provider value={{ remoteTranslations, addRemoteTranslation }}>
      {children}
    </RemoteTranslationContext.Provider>
  )
}

export const useRemoteTranslations = () => {
  return useContext(RemoteTranslationContext)
}

export const RemoteTranslations = ({
  path,
  deferredPromise = () => Promise.resolve(undefined)
}) => {
  const { addRemoteTranslation } = useRemoteTranslations()

  useEffect(() => {
    if (typeof deferredPromise === 'function') {
      addRemoteTranslation(deferredPromise, path)
    } else if (typeof deferredPromise !== undefined) {
      console.error(`Invalid deferred promise`, deferredPromise)
    }
  }, [deferredPromise])

  return <></>
}
