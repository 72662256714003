import React, { useState, useEffect, useMemo } from 'react'
import useConfig from '../state/useConfig'
import { fetchTranslations } from '../api/api'
import { IntlProvider } from 'react-intl'
import useUser from '../state/useUser'
import * as R from 'ramda'
import { useRemoteTranslations } from './RemoteTranslationProvider'

export const ReactIntlProvider = ({
  localeOverride,
  translations,
  children,
  onIntlError = R.T
}) => {
  const [user] = useUser()
  const config = useConfig()
  const locale = localeOverride ?? user.language ?? 'en'
  const [messages, setMessages] = useState(null)

  useEffect(() => {
    const controller = new AbortController()

    fetchTranslations(controller, config.staticAssetsPath, locale)
      .then((m) => {
        const newMessage = translations ? { ...m, ...translations[locale] } : m
        setMessages(newMessage)
      })
      .catch((error) => {
        if (!controller.signal.aborted) console.error(error)
      })

    return () => controller.abort()
  }, [locale, translations])

  const { remoteTranslations } = useRemoteTranslations()
  const mergedMessages = useMemo(() => {
    const localeMessages = remoteTranslations[locale] ?? {}
    const finalMessages = R.mergeDeepRight(messages, localeMessages)
    return finalMessages
  }, [messages, remoteTranslations, locale])

  return (
    <IntlProvider
      defaultLocale="en"
      locale={locale}
      messages={mergedMessages}
      onError={(e) => {
        if (onIntlError(e) === false) return // don't continue if provided onIntlerror function returns false
        if (process.env.NODE_ENV === 'test' && e.code === 'MISSING_TRANSLATION') return // don't log missing translations within tests
        if (process.env.NODE_ENV !== 'production')
          // default behavior
          console.error(e)
      }}
    >
      {messages ? children : null}
    </IntlProvider>
  )
}

export default ReactIntlProvider
